import { Button, Carousel, Image } from "react-bootstrap";
import { Link } from "react-router-dom";


export default function StartPage() {
    return (
        <div className="start-page-wrapper">
            <div className="start-page-container">
                <div>
                    <img src="assets/logo_white.svg"/>
                </div>
                <div className="start-page-header-container">
                    <h1 className="start-page-header">Lundatouren</h1>
                </div>
                <div className="start-page-divider">
                    <hr />
                </div>
                <div>
                    <h2>Lunds Akademiska Golfklubb</h2>
                    <h3>Est 2024</h3>
                </div>
                <div>
                    <Button variant="light" href="./about" className="button-read-more">Läs mer</Button>
                </div>
                <div className="advert-carousel">
                    <Carousel>
                        <Carousel.Item>
                            <a href="https://2gringos.se" target="_blank"> <Image className="advert-image" src="assets/adv/2Gringos_2.png" /></a>
                        </Carousel.Item>
                        <Carousel.Item>
                            <a href="https://ruffgolf.eu/sv/" target="_blank"> <Image className="advert-image" src="assets/adv/ruff.png" /></a>
                        </Carousel.Item>
                    </Carousel>
                </div>
            </div>
        </div>
    )
}