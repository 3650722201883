import { Table } from 'react-bootstrap'
import './ViewRound.css'

interface IRoundTableRowProps {
    rowHeader? : string
    values : Array<string> | Array<number>
    toPar? : Array<number> 
    valueClassNames? : Array<string>
    subTotal? : string
    total? : string
    rowClassname? : string
}
function RoundTableRow(props : IRoundTableRowProps) {


    return(
        <tr className={props.rowClassname ?? ""}>
            <td>{props.rowHeader ?? ""}</td>
            { 
                props.values.map( (val, idx) => {

                    let className = ""

                    if(props.valueClassNames != undefined) {
                        if(props.valueClassNames.length > idx) {
                            className += " " + props.valueClassNames[idx]
                        }
                    }

                    if(props.toPar != undefined) {
                        if(props.toPar[idx] == 0) className = "score-par"
                        if(props.toPar[idx] == -1) className = "score-birdie"
                        if(props.toPar[idx] == -2 && +val > 1) className = "score-eagle"
                        if(val == "1") className = "score-hio"
                        if(props.toPar[idx] == 1 ) className = "score-bogey"
                        if(props.toPar[idx] == 2 ) className = "score-dblbogey"
                        if(props.toPar[idx] > 2 ) className = "score-worse"
                    }

                    return(
                        <td key={idx}>
                            <div className={"score " + className}>
                                <p>{val}</p>
                            </div>
                        </td>
                    )
                })
            }
            { props.subTotal ? <td>{props.subTotal ?? ""}</td> : null }
            { props.total ? <td>{props.total ?? ""}</td> : null }
        </tr>

    )
}

interface IRoundTable9HolesProps {
    score : Array<number>
    holePar : Array<number>
    holeNo : Array<number>
    subTotalText : string
    totalText : string
    totalScore? : number
    totalPar? : number
}
function RoundTable9Holes(props : IRoundTable9HolesProps) {

    const subTotalPar = props.holePar.reduce( (acc, val) => acc + val, 0)
    const subTotalScore = props.score.reduce( (acc, val) => acc + val, 0)
    const totalValue : string = props.totalScore == undefined ? "" : "" + props.totalScore 
    const totalPar : string = props.totalPar == undefined ? "" : "" + props.totalPar 
    const cols = ["","","","","","","","",""]

    const toPar = props.score.map( (s, idx) => s - props.holePar[idx])

    return (
        <Table>
            {/* <thead>
                <RoundTableRow values={cols} />
            </thead> */}
            <tbody>
                <RoundTableRow values={props.holeNo} rowHeader='Hål' rowClassname='result-hole-row' subTotal={props.subTotalText} total={props.totalText}/> 
                <RoundTableRow values={props.holePar} rowHeader='Par' rowClassname='result-hole-row' subTotal={"" + subTotalPar} total={totalPar}/> 
                <RoundTableRow values={props.score} rowHeader='Res' rowClassname='result-score-row' subTotal={"" + subTotalScore} total={totalValue} toPar={toPar}/> 
            </tbody>
        </Table>
    )
}



export interface IRoundTableProps {
    score : Array<number>
    holePar : Array<number>
}
export function RoundTable(props : IRoundTableProps) {

    const hole1_9 : Array<number> = [1,2,3,4,5,6,7,8,9]
    const hole10_18 : Array<number> = [10,11,12,13,14,15,16,17,18]

    const totalScore = props.score.reduce( (acc, val) => acc + val, 0)
    const totalPar = props.holePar.reduce( (acc, val) => acc + val, 0)

    return (
        <div className="round">
            <div className="round-nine round-first-nine">
                <RoundTable9Holes score={props.score.slice(0,9)} holePar={props.holePar.slice(0,9)} holeNo={hole1_9} subTotalText="UT" totalText=""/>
            </div>
            <div className="round-nine round-last-nine">
                <RoundTable9Holes score={props.score.slice(9,18)} holePar={props.holePar.slice(9,18)} holeNo={hole10_18} subTotalText="IN" totalText="Tot" totalScore={totalScore} totalPar={totalPar}/>
            </div>
        </div>
    )

}

export interface IStatisticTable {
    names : Array<string>
    scores : Array<Array<number>>
    holePar : Array<number>
}
export function StatisticTable(props : IStatisticTable) {

    const holeNos : Array<number> = [1,2,3,4,5,6,7,8,9, 10, 11, 12, 13, 14, 15, 16, 17, 18]



    return (
        <div className="round">
            <Table>
                <tbody>
                    <RoundTableRow values={holeNos} rowHeader='Hål' rowClassname='result-hole-row' /> 
                    <RoundTableRow values={props.holePar} rowHeader='Par' rowClassname='result-hole-row' /> 
                    {
                        props.names.map( (n : string, idx : number) => {

                            let valueClassNames = undefined

                            if(n == "Diff") {
                                const sortedScores = [...props.scores[idx]].sort( (a,b) => a - b)
                                valueClassNames = props.scores[idx].map( (s, idx) => {
                                    const scorePos = sortedScores.findIndex( e => e == s)
                                    if (scorePos == 0) return "score-best1"
                                    if (scorePos == 1) return "score-best2"
                                    if (scorePos == 2) return "score-best3"
                                    if (scorePos == 17) return "score-worse1"
                                    if (scorePos == 16) return "score-worse2"
                                    if (scorePos == 15) return "score-worse3"
                                    return ""
                                })
                            }

                            return (
                                <RoundTableRow values={props.scores[idx]} rowHeader={n} rowClassname='result-score-row' valueClassNames={valueClassNames}/> 
                            )
                        })
                    }
                </tbody>
            </Table>

        </div>
    )

}


