import React, { createContext, useEffect, useMemo, useState } from "react";
import { TourSeason, useTourSeasonApi } from "./api/TourSeasonApi";
import useCookie from "./utils/useCookie";


//
//  Definition of context
//
export type TAppContext = {
    // logged in player
    playerId : string, 
    // method to logout player                                     
    logout: () => void,
    // method to login player
    login: (playerId : number) => void,
    // current tourId
    //tourId : number,
    // method to select new season
    selectTourSeason : (tourSeasonId : number) => void,
    // current tourseason
    tourSeason : TourSeason
}

// TODO: Is this needed?
const defaultContext = {    playerId : "", 
                            seasonId : 0, 
                            tourId : 1,
                            logout : () => null, 
                            login : (playerId : number) => null, 
                            selectTourSeason : (tourSeasonId : number) => null,
                            tourSeason : {tourSeasonId : 0, name : "", shortName: "", year:0, sortOrder:1, active:0, gameTypeId : 0, hcpReduction : 1}}


//
//    
//
export const AppContext = createContext<TAppContext>(defaultContext);

//
//  Provider of Context = All logic :)
//
export const AppContextProvider: React.FC<{children: React.ReactNode}> = (children) => {

    // Cookie for logged in player
    const {cookieValue: playerId, setCookie : setPlayerId, deleteCookie : deletePlayerId} = useCookie("user", "0") 

    // Current TourSeason
    const [tourSeason, setTourSeason] = useState<TourSeason>(defaultContext.tourSeason) 
    
    // List of all TourSeasons 
    // TODO: Is this needed 
    const {data : tourSeasons} = useTourSeasonApi();

    // Set tourSeason when data is loaded - Default is last for current year ()
    useEffect( () => {
        const year = new Date().getFullYear()
        if(tourSeasons.length > 0) {
            const currentTs = tourSeasons.filter( (ts) => ts.active === 1 && ts.year == year)
            if(currentTs.length > 0) {
                const tsId = currentTs.sort( (a,b) => b.sortOrder - a.sortOrder)[0]
                selectTourSeasonId(tsId.tourSeasonId)
            } else {
                const tsId =  tourSeasons.sort( (a,b) => b.sortOrder - a.sortOrder)[0]
                selectTourSeasonId(tsId.tourSeasonId)
            }
        } 
    }, [tourSeasons])

    // logout => remove cookie
    const logout = () => {
        deletePlayerId(); 
    }

    // Login => set cookie 
    const login = (pid : number) => {
        let expDate = new Date()
        expDate.setDate(expDate.getDate() + 1800)
        setPlayerId(pid.toString(), expDate )
    }

    // Change tourSeason => make tourseason active and set cookie to current TourSeason
    const selectTourSeasonId = (tourSeasonId : number) => {

        let expDate = new Date()
        expDate.setDate(expDate.getDate() + 1800)
        const ts = tourSeasons.find( (ts) => ts.tourSeasonId === tourSeasonId)
        setTourSeason(ts!)
    }

    
    return (
        <AppContext.Provider value={{   playerId : playerId, 
                                        logout : logout, 
                                        login : login, 
                                        selectTourSeason : selectTourSeasonId,
                                        tourSeason : tourSeason}}>
            {children.children}
        </AppContext.Provider>
    );
};